/* istanbul ignore file */

import {
  ErrorPage,
  getAppLocation,
  HttpErrorPage,
  PageLoading,
  SettingsContext,
  useRequestInit
} from "adviesbox-shared";
import React, { ReactElement, useContext, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import useAbortableFetch from "use-abortable-fetch";
import UserDetailsContext from "../user-details/user-details-context";
import AuthenticatedBerekeningenAppWithDrawer from "./AuthenticatedBerekeningenAppWithDrawer";

const AuthenticatedBerekeningenApp = (): ReactElement => {
  const settingsContext = useContext(SettingsContext);
  const linkToPortal = getAppLocation(window.location.origin, "BER", "POR", settingsContext.baseUrls);

  const { setUserDetails, userDetails } = useContext(UserDetailsContext);
  const { user, settings, requestInit } = useRequestInit();
  const medewerkerUrl = user && user.profile ? `${settings.licentiesOrigin}/Medewerkers/current` : null;
  const medewerker = useAbortableFetch(medewerkerUrl, requestInit) as any;
  const rechtenUrl =
    medewerker.data && typeof medewerker.data !== "string"
      ? `${settings.licentiesOrigin}/Medewerkers/${medewerker.data.medewerkerId}/Rechten`
      : null;
  const authorization = useAbortableFetch(rechtenUrl, requestInit) as any;

  useEffect(() => {
    if (
      !medewerker ||
      !medewerker.data ||
      typeof medewerker.data === "string" ||
      !medewerker.data.isValid ||
      !medewerker.data.medewerker ||
      !medewerker.data.medewerkerId ||
      !medewerker.data.organisatieId ||
      medewerker.data.medewerkerId === userDetails.medewerkerId
    )
      return;

    const newUserDetails = {
      ...medewerker.data.medewerker,
      medewerkerId: medewerker.data.medewerkerId,
      organisatieId: medewerker.data.organisatieId
    };

    setUserDetails(newUserDetails);
  }, [setUserDetails, medewerker, userDetails.medewerkerId]);

  if (!user || !user.profile) {
    return <ErrorPage error={Error("Geen rechten toegekend voor deze gebruiker")} data={null} />;
  }

  if (medewerker.error) {
    return <ErrorPage error={medewerker.error} data={medewerker.data} />;
  }

  if (!medewerker.data || medewerker.loading) {
    return <PageLoading />;
  }

  if (typeof medewerker.data === "string" || !medewerker.data.isValid) {
    return <ErrorPage error={Error("Communicatie fout, neem contact op met klantenservice")} data={null} />;
  }

  if (authorization.error) {
    return <ErrorPage error={authorization.error} data={authorization.data} />;
  }

  if (authorization.loading || !authorization.data) {
    return <PageLoading />;
  }

  if (typeof authorization.data === "string" || !authorization.data.isValid) {
    return <ErrorPage error={Error("Communicatie fout, neem contact op met klantenservice")} data={null} />;
  }

  if (authorization.data.rechten && !authorization.data.rechten.some((recht: any) => recht === "BER")) {
    return <ErrorPage error={{ ...Error("Geen rechten toegekend voor deze gebruiker"), status: 403 }} data={null} />;
  }

  return (
    <div data-testid="authenticated-app" className="adviesbox-advies">
      <Switch>
        <Route path="/" component={AuthenticatedBerekeningenAppWithDrawer} />
        <Route>
          <HttpErrorPage status={404} returnUrl={linkToPortal} />
        </Route>
      </Switch>
    </div>
  );
};

AuthenticatedBerekeningenApp.displayName = "AuthenticatedBerekeningenApp";
export default AuthenticatedBerekeningenApp;
