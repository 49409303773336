/* istanbul ignore file */
import { AuthContext, ErrorMessageBox, getCookie, getNaamAdviseur, getOTAP, NotificatiesProvider, useUpscope } from "adviesbox-shared";
import React, { ReactElement, useContext } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { MaandlastenBerekening } from "../maandlasten/maandlasten-berekening";
import { MaximaleHypotheek } from "../maximale-hypotheek/maximale-hypotheek";
import Drawer from "../shared/components/drawer/Drawer";
import UserDetailsContext from "../user-details/user-details-context";
import TopNavigation from "../shared/components/top-navigation/TopNavigation";
import "./AuthenticatedBerekeningenAppWithDrawer.scss";

const AuthenticatedBerekeningenAppWithDrawer = (): ReactElement => {
  const { user } = useContext(AuthContext);
  const { userDetails } = useContext(UserDetailsContext);
  useUpscope(user?.profile.name, [getNaamAdviseur(userDetails)]);

  const vestigingIdFromCookie = getCookie(`${getOTAP(window.location.hostname)}_vestigingid`);
  const vestigingId = typeof vestigingIdFromCookie === "string" ? vestigingIdFromCookie : "";

  return (
    <div data-testid="authenticated-berekeningen-app-with-router">
      <NotificatiesProvider reloadTimeInSeconds={60} medewerkerId={userDetails.medewerkerId} vestigingId={vestigingId} sourceApp={"BER"}>
        <Drawer>
          <TopNavigation />
          <ErrorMessageBox />
          <div className="authenticated-schermen">
            <Switch>
                <Route path="/maandlasten" component={MaandlastenBerekening} />
                <Route path="/maximale-hypotheek" component={MaximaleHypotheek} />
                <Redirect from="/vestiging/:vestiging" to="/maandlasten" />
                <Redirect from="/" to="/maandlasten" />
            </Switch>
          </div>
        </Drawer>
      </NotificatiesProvider>
    </div>
  );
};

export default AuthenticatedBerekeningenAppWithDrawer;
