import { AdviseurMenu, ErrorPage, InvalidAdviesboxResultErrorPage, NavigationBar, NotificatiesMenu, PageLoading, SupportMenu, SupportRemoteAssistanceModal, SupportTicketModalAjax, getCookie, getOTAP, useRequestInit } from "adviesbox-shared";
import React, { ReactElement, useContext, useState } from "react";

import classes from "./TopNavigation.module.scss";
import UserDetailsContext from "../../../user-details/user-details-context";
import useAbortableFetch from "use-abortable-fetch";
import { OrganisatiesOutput, VestigingenOutput } from "../../../.generated/licenties/licentiestypes";
import "./TopNavigation.scss"
import { AppDataContext } from "../../../navigation/appdata-context";

const TopNavigation = (): ReactElement => {
  const { activePage } = useContext(AppDataContext);
  const { params, user } = useRequestInit<{ vestiging: string; adviesdossier: string }>();
  const [showMeldingmaken, setShowMeldingMaken] = useState(false);
  const [showRemoteAssistance, setShowRemoteAssistance] = useState(false);
  const [showInvalidAdviesboxResultErrorPage, setShowInvalidAdviesboxResultErrorPage] = useState(false);

  const vestigingIdFromCookie = getCookie(`${getOTAP(window.location.hostname)}_vestigingid`);
  const vestigingId = typeof vestigingIdFromCookie === "string" ? vestigingIdFromCookie : "";

  const { userDetails } = useContext(UserDetailsContext);
  const { settings, requestInit } = useRequestInit();

  const vestiging = useAbortableFetch<VestigingenOutput>(
    `${settings.licentiesOrigin}/Vestigingen/${vestigingId}`,
    requestInit
  );

  const organisatieId = userDetails.organisatieId ? userDetails.organisatieId : null;
  const organisatieUrl = organisatieId ? `${settings.licentiesOrigin}/Organisaties/${organisatieId}` : null;
  const organisatie = useAbortableFetch<OrganisatiesOutput>(organisatieUrl, requestInit);

  if (vestiging.error) {
    return <ErrorPage error={vestiging.error} />;
  }

  /* istanbul ignore else */
  if (organisatie.error) {
    /* istanbul ignore next */
    return <ErrorPage error={organisatie.error} />;
  }

  if (vestiging.loading || !vestiging.data || organisatie.loading || !organisatie.data) {
    return <PageLoading />;
  }

  const vestigingNaam =
    vestiging.data && typeof vestiging.data !== "string" && vestiging.data.isValid && vestigingId
      ?/* istanbul ignore next */  vestiging.data.vestigingen?.[vestigingId].naam
      : "";
  const organisatieNaam =
    organisatie.data && typeof organisatie.data !== "string" && organisatieId
      ? organisatie.data.organisaties?.[organisatieId].naam
      : "";

  if (showInvalidAdviesboxResultErrorPage) {
    return <InvalidAdviesboxResultErrorPage />;
  }

  return (
    <>
      <NavigationBar title={activePage}>
        <SupportMenu
          showMeldingMaken={() => setShowMeldingMaken(true)}
          showRemoteAssistance={() => setShowRemoteAssistance(true)}
          classes={{
            iconblack: classes.iconblack,
            iconpadding: classes.iconpadding
          }}
        />
        <NotificatiesMenu />
        <AdviseurMenu vestigingNaam={vestigingNaam} organisatieNaam={organisatieNaam} />
      </NavigationBar>
      {/* istanbul ignore next */ showMeldingmaken && user && (
        <SupportTicketModalAjax
          adviesdossierId={params.adviesdossier ?? null}
          user={user}
          closeModal={() => setShowMeldingMaken(false)}
          showInvalidResultErrorPage={() => setShowInvalidAdviesboxResultErrorPage(true)}
          vestigingId={vestigingId}
        />
      )}
      {/* istanbul ignore next */ showRemoteAssistance && (
        <SupportRemoteAssistanceModal closeModal={() => setShowRemoteAssistance(false)} />
      )}
    </>
  )
};

TopNavigation.displayName = "TopNavigation";

export default TopNavigation;
